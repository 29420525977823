

export const Variables = {
    styles: {
        global: {
            ":root": {
                "--chakra-zIndices-modal": 1900,  
            },
        },
    }
};