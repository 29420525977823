// ProtectedRoute.tsx
import React from 'react';
import {  Navigate } from 'react-router-dom';
import { useRole } from '../context/ContextProvider';

interface ProtectedRouteProps  {
  element: React.ComponentType;
  roleRequired: string;
}

const ProtectedRoute = ({ element:Component , roleRequired }:ProtectedRouteProps) => {
  const { role ,loading} = useRole();
  return (
    <>{
        !loading &&( role && role === roleRequired ? (
          <Component />
        ) : (
          <Navigate to="/auth"  />
        ))
      }
    </>
  );
};

export default ProtectedRoute;
