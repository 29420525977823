import axios from "axios";

let baseUrl=window.location.origin
let path=baseUrl+ "/api"
//const path="http://localhost:5015"
//const path="http://192.168.100.8:5015"

const api = axios.create({
  baseURL: path,
  mode:'cors',
});

// Request interceptor for adding the auth token header to requests
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor for handling token expiration and refreshing
api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;

      try {
        const token = localStorage.getItem("token");
        if (!token) {
          return Promise.reject(error);
        }

        const decodedToken = JSON.parse(atob(token.split(".")[1]));
        console.log(decodedToken);
        if (decodedToken.email) {
          const res = await axios.post(
            path + "/auth/refresh-token",
            {
              email: decodedToken.email,
            },
            { withCredentials: true }
          );

          if (res.data) {
            console.log(res.data);
            localStorage.setItem("token", res.data);

            originalRequest.headers["Authorization"] = "Bearer " + res.data;

            return api(originalRequest);
          } else {
            localStorage.removeItem("token");
            window.location.href = "/auth/sign-in/default";
            return Promise.reject(error);
          }
        }
      } catch (error) {
        console.log("Error refreshing token:", error);
        localStorage.removeItem("token");
        window.location.href = "/auth/sign-in/default";
        return Promise.reject(error);
      }
    }

    return Promise.reject(error);
  }
);
export const gpath=path;
export default api;
