import { Box, Center, Spinner } from "@chakra-ui/react";



const Loader = () => {




    return (

        <Center mx='auto' p={{ base: '20px', md: '30px' }} pe='20px' minH='100vh' pt='50px'>
            <Spinner
                width="5rem"
                height="5rem"
                thickness='4px'
                speed='0.65s'
                emptyColor='gray.200'
                color='blue.500'
                size='xl'
            />    
        </Center> 
    )
}
export default Loader;