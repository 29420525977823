import ErrorBoundary from "components/errorBoundery/ErrorBoundary";
import { useRole } from "context/ContextProvider";
import Loader from "Loader";
import ProtectedRoute from "Protected_route/ProtectedRoutes";
import React, { FC, lazy, Suspense, useState } from "react";
import { createBrowserRouter, Navigate } from "react-router-dom";



// lazy loaded components
const ProfileSupplierMaker = lazy(()=>import("views/fournisseur/profile/ProfileSupplierMaker"));
const SupplierProfile = lazy(() => import("views/superResponsible/suppliers/component/SupplierProfile"))
const SignInCentered = lazy(() => import("views/auth/signIn"));
const ForgorPassword = lazy(() => import("views/auth/forgotPassword/ForgotPassword"));
const DisplayListPermitFromDashboard = lazy(() => import("views/responsable/zoneCard/display_permit_list/DisplayListPermitFromDashboard"));
const MainDashboard = lazy(() => import("views/admin/default"));
const EmployeeConfirm = lazy(
  () => import("views/auth/employeeConfirmAcount/EmployeeConfirm")
);
const SignUpCentered = lazy(() => import("views/auth/signUp"));
const DashboardSuperResponsible = lazy(() => import("views/superResponsible/dashboard/DashboardSuperResponsible"));
const ProfileSuperResponsable = lazy(() => import("views/superResponsible/profile"));

const AssigneIndex = lazy(
  () => import("views/superResponsible/assigne-risque-to-division/AssigneIndex")
);
const SuperResponsableListe = lazy(
  () => import("views/entreprise/superResponsible/SuperResponsableListe")
);
const ListMain = lazy(
  () => import("views/employee/List-other-permis/ListMain")
);
const ListPermis = lazy(() => import("views/employee/list-permis/ListPermis"));
const ProfileEmployee = lazy(
  () => import("views/employee/profile/ProfileEmployee")
);
const TypeTravaux = lazy(
  () => import("views/entreprise/TypeTravaux/TypeTRavaux")
);
const AddTypeTravaux = lazy(
  () => import("views/entreprise/critical-risk/AddTypeTravaux")
);
const EntrepriseDivision = lazy(
  () => import("views/entreprise/entrepriseDivision")
);
const ProfileEnterprise = lazy(() => import("views/entreprise/profile"));
const ResponsableListe = lazy(
  () => import("views/superResponsible/responsable/ResponsableListe")
);
const SousZone = lazy(() => import("views/superResponsible/sousZone/SousZone"));
const Fournisseur = lazy(() => import("views/superResponsible/suppliers"));
const ZoneList = lazy(() => import("views/superResponsible/zoneList/ZoneList"));
const EmployeesList = lazy(
  () => import("views/fournisseur/employees/EmployeesList")
);
const FournisseurProfile = lazy(
  () => import("views/fournisseur/profile/FournisseurProfile")
);
const ProfileResponsable = lazy(() => import("views/responsable/profile"));
const ListDemand = lazy(
  () => import("views/superResponsible/entreprise-demand/ListDemand")
);
const ListDemandPermis = lazy(
  () => import("views/fournisseur/List-demand-from-entreprise/ListDemandPermis")
);
const ConfirmSupplier = lazy(
  () => import("components/confirmation/ConfirmSupplier")
);
const ConfirmationPage = lazy(
  () => import("components/confirmation/ConfirmationPage")
);
const CriticalityTable = lazy(
  () => import("views/entreprise/criticality/component/CriticalityTable")
);
const ResponsableListPermit = lazy(
  () => import("views/responsable/demandPermitList/ResponsableListPermit")
);
const ZoneCardMain = lazy(
  () => import("views/responsable/zoneCard/ZoneCardMain")
);
const ListDemandResponsible = lazy(
  () => import("views/responsable/responsible-demand/ListDemand")
);
const DashboardResponsible = lazy(
  () => import("views/responsable/dashboard/DashboardResponsible")
);
const GlobalAdminProfile = lazy(
  () => import("views/globalAdmin/profile/GlobalAdminProfile")
)
const Demandlist = lazy(
  () => import("views/globalAdmin/demands/DemandList")
)
const EntreprisesList = lazy(
  () => import("views/globalAdmin/entreprises/EntreprisesList")
)
const SuppliersList = lazy(
  () => import("views/globalAdmin/suppliers/SuppliersList")
)
const GlobalAdminDashboard = lazy(
  () => import("views/globalAdmin/dashboard/GlobalAdminDashboard")
  
)

const ProfileEnterprises=lazy(
  () => import("views/globalAdmin/entreprises/ProfileEnterprise")
)
 
//lazy loaded layouts
const AuthLayout = lazy(() => import("layouts/auth"));
const AdminLayout = lazy(() => import("layouts/admin"));
const ResponsableLayout = lazy(() => import("layouts/responsable"));
const FournisseurLayout = lazy(() => import("layouts/fournisseur"));
const EmployeeLayout = lazy(() => import("layouts/employee"));
const SuperResponsibleLayout = lazy(() => import("layouts/superResponsible"));
const GlobalAdminLayout = lazy(() => import("layouts/GlobalAdmin"));


//custom navigate
const CustomNavigate = () => {
  const { role, loading } = useRole();
  return (
    <>{!loading && <Navigate to={role ? `${role}` : "/auth"} replace />}</>
  );
};


const reactroutes = createBrowserRouter([
  {
    path: "/auth",
    element: (
      <Suspense fallback={<Loader />}>
        <AuthLayout />
      </Suspense>
    ),
    children: [
      {
        path: "sign-in",
        element: (
          <Suspense fallback={<Loader />}>
            <SignInCentered />
          </Suspense>
        ),
      },
      {
        path: "sign-up",
        element: (
          <Suspense fallback={<Loader />}>
            <SignUpCentered />
          </Suspense>
        ),
      },
      {
        path: "reset",
        element: (
          <Suspense fallback={<Loader />}>
            <ForgorPassword />
          </Suspense>
        ),
      },
      {
        path: "sign-up",
        element: (
          <Suspense fallback={<Loader />}>
            <SignUpCentered />
          </Suspense>
        ),
      },
      {
        path: "Complete-Account",
        element: (
          <Suspense fallback={<Loader />}>
            <EmployeeConfirm />
          </Suspense>
        ),
      },
      {
        path: "Confirm-account",
        element: (
          <Suspense fallback={<Loader />}>
            <ConfirmationPage />
          </Suspense>
        ),
      },
      {
        path: "complete/supplier",
        element: (
          <Suspense fallback={<Loader />}>
            <ConfirmSupplier />
          </Suspense>
        ),
      },
      {
        path: "/auth",
        element: <Navigate to="/auth/sign-in" />,
      },
    ],
  },
  {
    path: "/entreprise",
    element: (
      <Suspense fallback={<Loader />}>
        <ProtectedRoute element={AdminLayout} roleRequired="entreprise" />
      </Suspense>
    ),
    children: [
      {
        path: "profile",
        element: (
          <Suspense fallback={<Loader />}>
            <ProfileEnterprise />
          </Suspense>
        ),
      },
      {
        path: "default",
        element: (
          <Suspense fallback={<Loader />}>
            <MainDashboard />
          </Suspense>
        ),
      },
      {
        path: "division",
        element: (
          <Suspense fallback={<Loader />}>
            <EntrepriseDivision />
          </Suspense>
        ),
      },
      {
        path: "superResponsible",
        element: (
          <Suspense fallback={<Loader />}>
            <SuperResponsableListe />
          </Suspense>
        ),
      },
      {
        path: "general-work-type",
        element: (
          <Suspense fallback={<Loader />}>
            <AddTypeTravaux />
          </Suspense>
        ),
      },
      {
        path: "procedure-work-type",
        element: (
          <Suspense fallback={<Loader />}>
            <TypeTravaux />
          </Suspense>
        ),
      },
    
      {
        path: "criticality",
        element: (
          <Suspense fallback={<Loader />}>
            <CriticalityTable />
          </Suspense>
        ),
      },
      {
        path: "/entreprise",
        element: <Navigate to="/entreprise/default" />,
      },
    ],
  },
  {
    path: "/responsable",
  
    element: (
      
        <Suspense fallback={<Loader />}>
          <ProtectedRoute
            element={ResponsableLayout}
            roleRequired="responsable"
          />
        </Suspense>
        
     
    ),
    children: [
      {
        path: "default",
        element: (
          <Suspense fallback={<Loader />}>
            <DashboardResponsible />
          </Suspense>
        ),
      },
      {
        path: "profile",
        element: (
          <Suspense fallback={<Loader />}>
            <ProfileResponsable />
          </Suspense>
        ),
      },
      {
        path: "permit",
        element: (
          <Suspense fallback={<Loader />}>
            <ResponsableListPermit />
          </Suspense>
        ),
      },
      {
        path: "filtred",
        element: (
          <Suspense fallback={<Loader />}>
            <DisplayListPermitFromDashboard />
          </Suspense>
        ),
      },
      {
        path: "card",
        element: (
          <Suspense fallback={<Loader />}>
            <ZoneCardMain />
          </Suspense>
        ),
      },
      {
        path: "demand",
        element: (
          <Suspense fallback={<Loader />}>
            <ListDemandResponsible />
          </Suspense>
        ),
      },
      {
        path: "/responsable",
        element: <Navigate to="/responsable/default" />,
      },
    ],
  },
  {
    path: "/fournisseur",
    element: (
      <Suspense fallback={<Loader />}>
        <ProtectedRoute
          element={FournisseurLayout}
          roleRequired="fournisseur"
        />
      </Suspense>
    ),
    children: [
      {
        path: "default",
        element: (
          <Suspense fallback={<Loader />}>
            <MainDashboard />
          </Suspense>
        ),
      },
      {
        path: "employeelist",
        element: (
          <Suspense fallback={<Loader />}>
            <EmployeesList />
          </Suspense>
        ),
      },
      {
        path: "demandlist",
        element: (
          <Suspense fallback={<Loader />}>
            <ListDemandPermis />
          </Suspense>
        ),
      },
      {
        path: "profile",
        children: [
          {
            path:"",
            element: (
              <Suspense fallback={<Loader />}>
                <FournisseurProfile />
              </Suspense>
            ),
          },
          {
            path:"external",
            element: (
              <Suspense fallback={<Loader />}>
                <ProfileSupplierMaker />
              </Suspense>
            ),
          }
        ]

      },
      {
        path: "/fournisseur",
        element: <Navigate to="/fournisseur/default" />,
      },
    ],
  },
  {
    path: "/employee",
    element: (
      <Suspense fallback={<Loader />}>
        <ProtectedRoute element={EmployeeLayout} roleRequired="employee" />
      </Suspense>
    ),
    children: [
      {
        path: "default",
        element: (
          <Suspense fallback={<Loader />}>
            <MainDashboard />
          </Suspense>
        ),
      },
      {
        path: "list-permis-saved",
        element: (
          <Suspense fallback={<Loader />}>
            <ListPermis />
          </Suspense>
        ),
      },
      {
        path: "list-permis-other",
        element: (
          <Suspense fallback={<Loader />}>
            <ListMain />
          </Suspense>
        ),
      },
      {
        path: "profile",
        element: (
          <Suspense fallback={<Loader />}>
            <ProfileEmployee />
          </Suspense>
        ),
      },
      {
        path: "/employee",
        element: <Navigate to="/employee/default" />,
      },
    ],
  },
  {
    path: "/superresponsible",
    element: (
      <Suspense fallback={<Loader />}>
        <ProtectedRoute element={SuperResponsibleLayout} roleRequired="superresponsible" />
      </Suspense>
    ),
    children: [
      {
        path: "default",
        element: (
          <Suspense fallback={<Loader />}>
            <DashboardSuperResponsible />
          </Suspense>
        ),
      },
      {
        path: "zones",
        element: (
          <Suspense fallback={<Loader />}>
            <ZoneList />
          </Suspense>
        ),
      },
      {
        path: "subzone",
        element: (
          <Suspense fallback={<Loader />}>
            <SousZone />
          </Suspense>
        ),
      },
      {
        path: "responsable",
        element: (
          <Suspense fallback={<Loader />}>
            <ResponsableListe />
          </Suspense>
        ),
      }, {
        path: "assigne-risk",
        element: (
          <Suspense fallback={<Loader />}>
            <AssigneIndex />
          </Suspense>
        ),
      },
      {
        path: "suppliers",
        children: [
          {
            path: "",
            element: (
              <Suspense fallback={<Loader />}>
                <Fournisseur />
              </Suspense>
            ),

          }, {
            path: "profile",
            element: (
              <Suspense fallback={<Loader />}>
                <SupplierProfile />
              </Suspense>
            )
          }
        ]
      },
      {
        path: "permis-demand",
        element: (
          <Suspense fallback={<Loader />}>
            <ListDemand />
          </Suspense>
        ),
      },
      {
        path: "filtred",
        element: (
          <Suspense fallback={<Loader />}>
            <DisplayListPermitFromDashboard />
          </Suspense>
        ),
      },
      {
        path: "profile",
        element: (
          <Suspense fallback={<Loader />}>
            <ProfileSuperResponsable />
          </Suspense>
        ),
      },
      {
        path: "/superresponsible",
        element: <Navigate to="/superresponsible/default" />,
      },
    ],
  },
  {
    path: "/admin",
    element: (
      <Suspense fallback={<Loader />}>
        <ProtectedRoute element={GlobalAdminLayout} roleRequired="admin" />
      </Suspense>
    ),
    children: [
      {
        path: "default",
        element: (
          <Suspense fallback={<Loader />}>
            <GlobalAdminDashboard />
          </Suspense>
        ),
      },
      {
        path: "demands",
        element: (
          <Suspense fallback={<Loader />}>
            <Demandlist />
          </Suspense>
        ),
      },
      {
        path: "enterprises",
        children: [
          {
            path: "",
            element: (
              <Suspense fallback={<Loader />}>
                <EntreprisesList />
              </Suspense>
            ),
          }, {
            path:"profile",
            element:(
              <Suspense fallback={<Loader />}>
                <ProfileEnterprises />
              </Suspense>
            )
          }
        ]
      },
      {
        path: "suppliers",
            element: (
              <Suspense fallback={<Loader />}>
                <SuppliersList />
              </Suspense>
            )

      },
      {
        path: "profile",
        element: (
          <Suspense fallback={<Loader />}>
            <GlobalAdminProfile />
          </Suspense>
        ),
      },
      {
        path: "/admin",
        element: <Navigate to="/admin/default" />,
      },
    ],
  },
  {
    path: "*",
    element: <CustomNavigate />,
  },
]);

export default reactroutes;
